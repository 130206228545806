import { Box } from "@mui/system";
import React from "react";
import LoadingSvg from "../../common/Image/LoadingSvg";
import { TSubjectType, TTopListResponse } from "../../common/utils/type";
import { findFirstSubjectBySubjectType } from "../utils/constants";

type TProps = {
  displayList: TTopListResponse["display_list"] | any;
  subjectList: TSubjectType[];
  // eslint-disable-next-line no-unused-vars
  handleOpenModalChooseBook: (id: number, slug: string) => void;
};
function Display({ displayList, subjectList, handleOpenModalChooseBook }: TProps) {
  return (
    <Box className="flex flex-col box-cover md:w-full sm:w-screen text-black">
      <span className="text-black font-semibold text-lg mb-2">{displayList?.items?.length > 0 && displayList?.title}</span>
      <Box className="sm:gap-x-5 sm:overflow-x-auto w-full md:gap-12x md:overflow-visible scrollbar-hide grid 2xl:grid-cols-5 md:grid-cols-4 sm:grid-cols-3">
        {subjectList?.map((ele, index) => {
          return (
            <Box
              position="relative"
              key={`${index}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleOpenModalChooseBook(ele?.id, ele?.slug);
              }}
              display="flex"
              mt={2}
              flexDirection="column"
              alignItems="center"
            >
              <LoadingSvg
                fileName={findFirstSubjectBySubjectType(displayList?.items, ele?.id)?.asset_photo}
                netPhoto={ele?.asset_photo}
                styleSvg={undefined}
                styleImg={{
                  width: 104,
                  height: 104
                }}
              />
              <span
                style={{ maxWidth: 120, textAlign: "center" }}
                className="text-[16px] font-[400] bottom-5 text-white absolute"
              >
                {ele?.title}
              </span>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default Display;
