import { Button, Divider, styled, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import XCircle from "../../../../public/assets/icons/XCircle";
import { getNonce, genSignature } from "../../../utils/helpers/helpers";
import { accountActions } from "../redux/actions";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 560,
    padding: "8px",
    borderRadius: "16px",
    height: "220px"
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    aligItems: "center",
    overFlow: "hidden !important"
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

export default function ChangeCodeDialog({ setOpen, open, valueCode, closeModalChangeCode }: any) {
  const [giftCode, setGiftCode] = React.useState<any>();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleSubmit = () => {
    const dataPostPre = {
      gift_code: giftCode || valueCode,
      timestamp: moment().unix(),
      nonce: getNonce()
    };
    const dataPost = {
      ...dataPostPre,
      signature: genSignature(dataPostPre)
    };
    dispatch(accountActions.handlePostGiftCode(dataPost));
    if (closeModalChangeCode) {
      closeModalChangeCode();
    } else {
      setOpen(false);
    }
  };
  const handleClose = () => {
    if (closeModalChangeCode) {
      closeModalChangeCode();
    } else {
      setOpen(false);
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      className="overflow-hidden"
    >
      <DialogTitle className="center-row text-black py-3">
        <div>
          <button
            type="button"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 7,
              top: 9,
              background: "white"
            }}
          >
            <XCircle />
          </button>
          <span className="font-semibold text-lg">
            <FormattedMessage id="Profile.redeemCode" />
          </span>
        </div>
      </DialogTitle>
      <Divider className="mx-4" />
      <div className="py-6">
        <div className="md:center-col overflow-hidden px-4">
          <TextField
            size="small"
            onChange={(event) => {
              setGiftCode(event.target.value.trim());
            }}
            placeholder={intl.formatMessage({
              id: "IDS_Change_Code_Placeholder"
            })}
            className="px-2 pt-1 w-full"
            // sx={{
            //   "& .MuiOutlinedInput-root": {
            //     "&.Mui-focused fieldset": {
            //       borderColor: theme.palette.primary.main
            //     }
            //   }
            // }}
            value={giftCode || valueCode}
          />
        </div>
      </div>
      <div className="center-row w-full sm2:gap-10 pb-[10px]">
        <Button onClick={handleClose} style={{ height: 40 }}>
          <span className="font-semibold text-primary text-[13px] normal-case">
            <FormattedMessage id="cancel" />
          </span>
        </Button>
        <Button
          onClick={handleSubmit}
          className={`text-white font-semibold rounded-[8px] text-[13px] px-10 py-2 normal-case ${
            !giftCode && !valueCode ? "bg-primary100" : "bg-primary"
          }`}
          disabled={!giftCode && !valueCode}
        >
          <FormattedMessage id="IDS_Apply" />
        </Button>
      </div>
    </CustomDialog>
  );
}
