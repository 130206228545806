import { Box, Button, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import Image from "next/image";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import TargetLearning from "../../../../public/assets/icons/TargetLearning";
import TargetDialog from "../../account/components/TargetDialog";
import { commonActions } from "../../common/redux/actions";
import { TCommonState } from "../../common/utils/type";
import { TConfig } from "../utils/types";
import { CONFIG } from "../../common/utils/constant";

export default function UsefulFeatures({ title }) {
  const [openTargetDialog, setOpenTargetDialog] = React.useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const { userInfo, topInfos } = useSelector((state: { commonNew: TCommonState }) => ({
    userInfo: state.commonNew.userInfos,
    topInfos: state.commonNew.listTops
  }));
  const isIpad = useMediaQuery("(max-width:1099px)");
  const handlePushLink = (link) => {
    if (isIpad) {
      router.push(link);
    } else {
      window.open(link, "_blank");
    }
  };
  const gameName = useMemo(
    () => topInfos?.config_list?.find((config: TConfig) => config.key === CONFIG.GAME_NAME),
    [topInfos?.config_list]
  );
  const gameLink = useMemo(
    () => topInfos?.config_list?.find((config: TConfig) => config.key === CONFIG.GAME_LINK),
    [topInfos?.config_list]
  );
  return (
    <>
      <Box className="md:w-full sm:w-screen flex flex-col box-cover">
        <span className="text-black font-semibold text-lg mb-2">{title}</span>
        <div className="flex sm:gap-x-5 md:gap-x-[27px] py-1 mt-2">
          {gameName && gameLink && (
            <Button
              sx={{ textTransform: "none", fontWeight: 400, width: 120, height: 130 }}
              className="line-clamp-2 py-3 rounded-[16px] bg-[#E4D0F0]"
              onClick={() => {
                if (typeof userInfo?.member?.pub_id === "undefined") {
                  dispatch(commonActions.handleOpenModalLogin(true));
                } else {
                  handlePushLink(gameLink.value);
                }
              }}
              style={{
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)"
              }}
            >
              <div className="flex justify-center ">
                <Image src="/assets/images/game.png" width={56} height={56} />
              </div>
              <span className="flex text-blackQuiz text-[15px] justify-center px-2 h-[40px] mt-[12px] leading-[18.75px]">
                {gameName.value}
              </span>
            </Button>
          )}
          <Button
            sx={{ textTransform: "none", fontWeight: 400, width: 120, height: 130 }}
            className="line-clamp-2 py-3 rounded-[16px] bg-[#FFEBAD]"
            onClick={() => {
              if (typeof userInfo?.member?.pub_id === "undefined") {
                dispatch(commonActions.handleOpenModalLogin(true));
              } else {
                setOpenTargetDialog(true);
              }
            }}
            style={{
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)"
            }}
          >
            <div className="flex justify-center ">
              <TargetLearning />
            </div>
            <span className="flex text-blackQuiz text-[15px] justify-center px-2 h-[40px] mt-[12px] leading-[18.75px]">
              <FormattedMessage id="UsefulFeatures.target" />
            </span>
          </Button>
          <Button
            sx={{ textTransform: "none", fontWeight: 400, width: 120, height: 130 }}
            className="line-clamp-2 py-3 rounded-[16px] bg-[#CAF2FF]"
            onClick={() => {
              if (typeof userInfo?.member?.pub_id === "undefined") {
                dispatch(commonActions.handleOpenModalLogin(true));
              } else {
                handlePushLink("/qua-tang");
              }
            }}
            style={{
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)"
            }}
          >
            <div className="flex justify-center ">
              <Image src="/assets/images/gift.png" width={56} height={56} />
            </div>
            <span className="flex text-blackQuiz text-[15px] justify-center px-2 h-[40px] mt-[12px] leading-[18.75px]">
              <FormattedMessage id="Profile.gift" />
            </span>
          </Button>
          <Button
            sx={{ textTransform: "none", fontWeight: 400, width: 120, height: 130 }}
            className="line-clamp-2 py-3 rounded-[16px] bg-[#FFF2E7]"
            onClick={() => {
              if (typeof userInfo?.member?.pub_id === "undefined") {
                dispatch(commonActions.handleOpenModalLogin(true));
              } else handlePushLink("/kim-cuong");
            }}
            style={{
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)"
            }}
          >
            <div className="flex justify-center">
              <Image src="/assets/images/EarnDiamond.png" width={55} height={56} />
            </div>
            <span className="flex text-blackQuiz text-[15px] justify-center px-2 h-[40px] mt-[12px] leading-[18.75px]">
              <FormattedMessage id="Profile.earnMoney" />
            </span>
          </Button>
        </div>
      </Box>
      {openTargetDialog && <TargetDialog open={openTargetDialog} setOpen={setOpenTargetDialog} />}
    </>
  );
}
