/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
import { TItemDisplay, TSubjectType, TTopListResponse } from "../../common/utils/type";

export const filterSubjectByList = (arr: TTopListResponse["display_list"][0]) => {
  const menuSubject = arr?.items as any as TItemDisplay[];
  let newList: TSubjectType[] = [];
  if (menuSubject?.length > 0) {
    newList = menuSubject?.map((item) => item?.subject_type);
  }
  const listSubjectType: any[] = [];
  if (newList.length > 0) {
    newList.forEach((item) => {
      const subjectType = newList.find((ele) => item?.id === ele?.id) as any;
      if (!listSubjectType?.find((e) => e?.id === subjectType?.id)) {
        listSubjectType.push(subjectType);
      }
    });
  }
  return listSubjectType || [];
};
export const filterBookById = (arr: TTopListResponse["display_list"][0], id) => {
  const menuSubject = arr?.items as any as TItemDisplay[];
  let newList: TItemDisplay[] = [];
  if (menuSubject?.length > 0) {
    newList = menuSubject?.filter((item) => item?.subject_type?.id === id);
  }
  return newList;
};

export const findFirstSubjectBySubjectType = (arr: TItemDisplay[], subjectTypeId: number) => {
  return arr?.find((e) => e?.subject_type?.id === subjectTypeId);
};

export const checkDuplicateInArr = (arr: any) => {
  let arrNew: any[] = [];
  arrNew = arr.filter((item, index) => item.learning_program?.slug === arr[index + 1]?.learning_program?.slug);
  return arrNew.length > 0;
};

export const LIST_EXTRA_INFO = [
  {
    type: "exam",
    title: "Đề thi",
    id: "Exams"
  },
  {
    type: "practice",
    title: "Bài tập",
    id: "Practice"
  },
  {
    type: "lesson",
    title: "Lý thuyết",
    id: "Contents"
  },
  {
    type: "card",
    title: "Lý thuyết",
    id: "Contents"
  }
];

export const KEY_BANNER_ADS_LOCATE = "home_banner_ads_locate";

export function isUrlValid(url: string): boolean {
  const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g;
  const result = url.match(urlRegex);

  return result !== null;
}

export const POSITION_ADS_IN_HOUSE = {
  promotion: 1,
  recommend: 2,
  bannerAds: 3
};

export const AVAILABLE_STATUS = {
  available: "common.save",
  saved: "common.saved",
  out_of_stock: "common.out_of_stock",
  out_of_turn: "common.out_of_turn",
  used: "common.used"
};

export const isPositiveOrZero = (number?: number): boolean => {
  return !!number || number === 0;
};
