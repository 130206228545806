export const PERCENT = 100;
export const DIAMOND_NONE = 0;
export const TYPE_GIFT_OTHER = "other";
export const TYPE_GIFT_GIFT_CODE = "gift_code";

export const checkNumberPhone = (str) => {
  const phone = /(84|0|3)+([0-9]{9})\b/;
  if (str.match(phone)) {
    return true;
  }
  return false;
};
export const GIFT_ITEM_HISTORY_STATUS_USED = "used";
export const GIFT_ITEM_HISTORY_STATUS_EXPRIRED = "expired";
export const GIFT_ITEM_HISTORY_STATUS_AVAILABLE = "available";
export const ERROR_CODE_NO_GIFT = "ENFGIT";
