/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import { Box } from "@mui/system";
import React, { useCallback, useRef } from "react";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsomorphicLayoutEffect, useUnmount } from "react-use";
import { commonActions } from "../../common/redux/actions";
import { TCommonState, TItemDisplay } from "../../common/utils/type";
import Banner from "../components/Banner";
import Display from "../components/Display";
import Feedback from "../components/Feedback";
import HotSubject from "../components/HotSubject";
// import OnlineLearning from "../components/OnlineLearning";
import "../style.module.scss";
import UsefulFeatures from "../components/UsefulFeatures";
import { checkDuplicateInArr, filterBookById, filterSubjectByList, isPositiveOrZero } from "../utils/constants";
import CircleLoading from "../../common/components/CircleLoadding";
import SuggestSubjectComponent from "../components/SuggestSubjectComponent";
import { ACCESS_LEVEL } from "../../subjects/utils/contants";
import RecommendComponent from "../components/RecommendComponent";
import NewsComponent from "../components/NewsComponent";
import { homeActions } from "../redux/actions";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import { clientKeyAdsInHouse, secretKeyAdsInHouse, uidAdsInHouse } from "../../../utils/constants/env";
import { TGiftCodes, THomeState } from "../utils/types";
import BannerAds from "../components/BannerAds";
import { ROUTES } from "../../../utils/configs/router";
import { accountActions } from "../../account/redux/actions";
import { TAccountState } from "../../account/utils/types";
import {
  ADS_PAGE_POSITION_HOME,
  // LOCATION_PERMISSION_UNDEFINE,
  POSITION_ADS_IN_HOUSE,
  removeNullAttributeFromObject
} from "../../common/utils/constant";
// import DialogAdsInHouse from "../components/DialogAdsInHouse";
import { handlePostActionClickAdsInHouse } from "../../common/utils/commonAPI";
import LearningStatistic from "../components/LearningStatistic";
import ArenaRoomHot from "../components/ArenaRoomHot";
import NewQuestionPost from "../components/NewQuestionPost";
import RedeemGift from "../components/RedeemGift";
import { eventTracking } from "../../../firebase/firebaseConfig";

const DEBOUNCE_TIMES_CALL_API = 500; // ms

const GiftCodeBox = dynamic(() => import("../components/GiftCodeBox"));
const SuggestedFriendHome = dynamic(() => import("../components/SuggestedFriendHome"));
const AlertDialog = dynamic(() => import("../../common/dialog/AlertDialog"));
const ModalConfirm = dynamic(() => import("../../common/components/ModalConfirm"), {
  ssr: false
});
const CXModal = dynamic(() => import("../../common/components/CXModal"), {
  ssr: false
});

function Home({ props }) {
  const { topInfos, listNews } = props;
  const intl = useIntl();
  const [openModalChooseBook, setOpenModalChooseBook] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const refAds = useRef<HTMLDivElement>(null);
  // const topInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.listTops);
  const {
    topExtra,
    // isShowAdsDialogFirstTime,
    adsInHouses,
    // congratulation,
    // locationPermission,
    action,
    // openDialogAdsInHouse,
    suggestedFriends,
    isLoadingTop
  } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const { giftCodes, notifyHome } = useSelector((state: { home: THomeState }) => state.home);
  const { goals } = useSelector((state: { account: TAccountState }) => state.account);
  const [nextUrl, setNextUrl] = React.useState("");
  const [openModalConfirmJoinQuiz, setOpenModalConfirmJoinQuiz] = React.useState(false);
  const [openModalConfirmJoinQuizPremium, setOpenModalConfirmJoinQuizPremium] = React.useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const dataUserInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const userClass = useSelector((state: { commonNew: TCommonState }) => state.commonNew.grade);
  const [listBook, setListBook] = React.useState<TItemDisplay[]>([]);

  const adsInHousesInBannerAds = adsInHouses?.filter((item) => item?.position === POSITION_ADS_IN_HOUSE.bannerAds);
  const adsInHousesInRecommend = adsInHouses?.filter((item) => item?.position === POSITION_ADS_IN_HOUSE.recommend);
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const handleOpenModalChooseBook = (id: number) => {
    setListBook(filterBookById(topInfos?.display_list, id));
    const listBookCheck = filterBookById(topInfos?.display_list, id);
    if (listBookCheck.length > 1) {
      return setOpenModalChooseBook(true);
    } else {
      const slugSubject = topInfos?.display_list?.items?.find((item) => item?.subject_type?.id === id)?.slug;
      return router.push(`${userClass}/${slugSubject}`);
    }
  };

  const handleDirectToSubjectPage = (ele) => {
    const params = {
      grade: userClass
    };
    const slug = listBook?.find((item) => item?.id === ele?.id)?.slug;
    dispatch(commonActions.handleChooseBook(ele?.learning_program?.id));
    router.push({
      pathname: ROUTES.subjects.path,
      query: {
        grade: params.grade,
        subjectsId: slug
      }
    });
  };
  const handleSaveGift = (item: TGiftCodes) => {
    const data = {
      gift_code: item?.code,
      timestamp: moment().unix(),
      nonce: getNonce()
    };
    const dataPost = {
      ...data,
      signature: genSignature(data)
    };
    if (item?.available_status === "available") {
      dispatch(homeActions.handleTakeGiftCode(dataPost));
    }
  };
  const handleDirectPageSuggest = useCallback((item, link) => {
    if (item?.is_learned) {
      return window.open(link, "_blank");
    }
    if (item?.access_level === ACCESS_LEVEL.LOGIN) {
      if (!dataUserInfos?.member?.pub_id) {
        return dispatch(commonActions.handleOpenModalConfirmLogin(true));
      } else if (item?.price > 0) {
        setNextUrl(link);
        return setOpenModalConfirmJoinQuiz(true);
      } else {
        return window.open(link, "_blank");
      }
    }
    if (ACCESS_LEVEL?.PREMIUM?.includes(item?.access_level)) {
      if (!dataUserInfos?.member?.pub_id) {
        return dispatch(commonActions.handleOpenModalConfirmLogin(true));
      } else if ((dataUserInfos?.member?.vip_to || 0) < moment().unix()) {
        return setOpenModalConfirmJoinQuizPremium(true);
      } else if (item?.price > 0) {
        setNextUrl(link);
        return setOpenModalConfirmJoinQuiz(true);
      } else {
        return window.open(link, "_blank");
      }
    } else {
      return window.open(link, "_blank");
    }
  }, []);

  const handleGetAdsInHouse = () => {
    const userInfo = dataUserInfos?.member;
    const listSubjectTypeId = goals?.goal_subjects?.map((e) => e?.subject_type_id);
    const params = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade: userClass,
      uid: uidAdsInHouse,
      position: ADS_PAGE_POSITION_HOME,
      school: userInfo?.school,
      province: userInfo?.city,
      platform: "web",
      device_cd: dataUserInfos?.device?.device_cd || Math.random().toString(32),
      is_impression: 1,
      learning_block_subject: userInfo?.learning_block_subject,
      exam_block_subject: userInfo?.exam_block_subject,
      subject_types: goals?.subject_types
        ?.filter((st) => listSubjectTypeId?.includes(st.id))
        ?.map((s) => s.slug)
        .toString()
    };
    const finalParams = removeNullAttributeFromObject(params);
    const dataGetListAds = {
      ...finalParams,
      signature: genSignature(finalParams, clientKeyAdsInHouse, secretKeyAdsInHouse)
    };
    dispatch(commonActions?.handleGetAdsInHouse(dataGetListAds));
  };

  const handleGetSuggestedFriend = useCallback(
    debounce(() => {
      dispatch(commonActions.handleGetSuggestedFriend());
    }, DEBOUNCE_TIMES_CALL_API),
    []
  );
  const handleGetGoals = useCallback(
    debounce(() => {
      dispatch(accountActions.handleGetGoals({ grade_slug: userClass }));
    }, DEBOUNCE_TIMES_CALL_API),
    [userClass]
  );

  const handleGetTopExtra = useCallback(
    debounce(() => {
      dispatch(commonActions.handleGetTopExtra({ grade: userClass }));
    }, DEBOUNCE_TIMES_CALL_API + 500),
    [userClass]
  );

  const handleGetGiftCode = () => {
    dispatch(homeActions.handleGetGiftCode());
  };

  const handleGetGiftCodeDebounce = useCallback(
    debounce(() => {
      handleGetGiftCode();
    }, 1000),
    []
  );

  React.useEffect(() => {
    if (topInfos?.grade !== userClass) {
      // refresh data top from getServerSideProps
      router.replace(router.asPath);
    }
  }, [userClass]);
  React.useEffect(() => {
    if (dataUserInfos?.member) {
      handleGetGoals();
    }
  }, [dataUserInfos?.member]);
  React.useEffect(() => {
    // đợi getGoals xong
    if (goals?.correct_quiz_rate > 0) {
      handleGetAdsInHouse();
    }
  }, [goals?.goal_subjects]);

  React.useEffect(() => {
    if (dataUserInfos?.member && suggestedFriends?.length === 0) {
      handleGetSuggestedFriend();
    }
  }, [dataUserInfos?.member]);
  React.useEffect(() => {
    if (!dataUserInfos?.member) {
      // get list ads khi chua dang nhap de hien thi promotion + banner_ads
      handleGetAdsInHouse();
    }
    eventTracking("view_dashboard");
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (width !== refAds.current?.offsetWidth) {
      setWidth(refAds.current?.offsetWidth as number);
    }
  }, [refAds.current?.offsetWidth]);
  useIsomorphicLayoutEffect(() => {
    // run only client side
    if (dataUserInfos?.member?.pub_id) {
      handleGetGiftCodeDebounce();
    }
    if (action?.type !== "GET_TOP_EXTRA") {
      handleGetTopExtra();
    }
  }, [dataUserInfos?.member?.pub_id, userClass]);

  React.useEffect(() => {
    if (notifyHome?.type === "HANDLE_TAKE_GIFT_CODE_SUCCESS") {
      handleGetGiftCode();
      dispatch(homeActions.handleClearNotifyData());
    }
  }, [notifyHome?.type]);
  React.useEffect(() => {
    if (adsInHouses?.findIndex((e) => e?.position === POSITION_ADS_IN_HOUSE.homeDialog) > -1) {
      dispatch(commonActions.handleOpenDialogAdsInHouse(true));
    }
  }, [adsInHouses]);
  useUnmount(() => {
    dispatch(commonActions.handleClearAdsInHouse());
  });

  if (isLoadingTop)
    return (
      <div className="w-full h-screen bg-white">
        <div className="w-full flex justify-center items-center h-1/2">
          <CircleLoading />
        </div>
      </div>
    );

  return (
    <>
      <div className="flex flex-col sm:w-screen md:w-full sm:gap-y-3 md:gap-y-6">
        <div className="md:w-full sm:w-screen md:px-0 order-[-99]" id="home_page_promotions">
          <Banner
            banners={topInfos?.promotions}
            handleClickAds={handlePostActionClickAdsInHouse}
            adsInHouse={adsInHouses?.filter((item) => item?.position === POSITION_ADS_IN_HOUSE.promotion)}
          />
        </div>
        {topInfos?.featured_list &&
          isPositiveOrZero(topInfos?.featured_list?.ordinal) &&
          topInfos?.featured_list?.items.length > 0 && (
            <Box order={topInfos?.featured_list?.ordinal} id="home_page_hot_subject">
              <HotSubject items={topInfos?.featured_list?.items} title={topInfos?.featured_list?.title || ""} />
            </Box>
          )}
        {topInfos?.suggested_list &&
          isPositiveOrZero(topInfos?.suggested_list?.ordinal) &&
          topInfos?.suggested_list?.items?.length > 0 && (
            <Box order={topInfos?.suggested_list?.ordinal}>
              <SuggestSubjectComponent
                suggestSubjects={topInfos?.suggested_list}
                handleDirectPage={handleDirectPageSuggest}
                title={topInfos?.suggested_list?.title}
              />
            </Box>
          )}
        {isPositiveOrZero(topInfos?.banner_ad?.ordinal) && (
          <Box className="w-full" order={topInfos?.banner_ad?.ordinal} ref={refAds} id="home_page_ads_in_houses">
            <BannerAds
              title={topInfos?.banner_ad?.title || ""}
              width={width}
              adsInHouse={adsInHousesInBannerAds}
              handleClickAds={handlePostActionClickAdsInHouse}
              matches1100={matches1100}
            />
          </Box>
        )}
        {isPositiveOrZero(topInfos?.display_list?.ordinal) && (
          <Box order={topInfos?.display_list?.ordinal}>
            <Display
              displayList={topInfos?.display_list}
              subjectList={filterSubjectByList(topInfos?.display_list)}
              handleOpenModalChooseBook={handleOpenModalChooseBook}
            />
          </Box>
        )}

        {topExtra?.learning_statistic && isPositiveOrZero(topExtra?.learning_statistic?.ordinal) && (
          <Box order={topExtra?.learning_statistic.ordinal}>
            <LearningStatistic learningStatistic={topExtra?.learning_statistic} />
          </Box>
        )}
        {isPositiveOrZero(topExtra?.hot_rooms?.ordinal) && (
          <Box order={topExtra?.hot_rooms?.ordinal}>
            <ArenaRoomHot roomHot={topExtra?.hot_rooms} subjects={topExtra?.subjects} />
          </Box>
        )}
        {isPositiveOrZero(topExtra?.new_posts?.ordinal) && (
          <Box order={topExtra?.new_posts?.ordinal}>
            <NewQuestionPost newestPosts={topExtra?.new_posts} subjects={topExtra?.subjects} />
          </Box>
        )}
        {isPositiveOrZero(topInfos?.gift_code?.ordinal) && !isEmpty(giftCodes) && dataUserInfos?.member && (
          <Box order={topInfos?.gift_code?.ordinal}>
            <GiftCodeBox
              title={topInfos?.gift_code?.title || ""}
              giftCodes={giftCodes}
              handleSaveGift={handleSaveGift}
              matches1100={matches1100}
            />
          </Box>
        )}
        {isPositiveOrZero(topExtra?.redeem_gifts?.ordinal) && (
          <Box order={topExtra?.redeem_gifts?.ordinal}>
            <RedeemGift redeemGifts={topExtra?.redeem_gifts} handleGetTopExtra={handleGetTopExtra} />
          </Box>
        )}
        {topInfos?.recommended_list &&
          isPositiveOrZero(topInfos?.recommended_list?.ordinal) &&
          topInfos?.recommended_list?.items?.length + adsInHousesInRecommend?.length > 0 && (
            <Box order={topInfos?.recommended_list?.ordinal} id="home_page_recommended">
              <RecommendComponent
                recommend={topInfos?.recommended_list}
                handleClickAds={handlePostActionClickAdsInHouse}
                adsInHouse={adsInHousesInRecommend}
              />
            </Box>
          )}
        {isPositiveOrZero(topInfos?.useful_feature?.ordinal) && (
          <Box
            className="sm:w-screen md:w-full"
            order={topInfos?.useful_feature?.ordinal}
            id="home_page_useful_features"
          >
            <UsefulFeatures title={topInfos?.useful_feature?.title || ""} />
          </Box>
        )}
        {isPositiveOrZero(topInfos?.friend_suggestion?.ordinal) &&
          suggestedFriends?.length > 0 &&
          dataUserInfos?.member && (
            <Box
              className="sm:w-screen md:w-full"
              order={topInfos?.friend_suggestion?.ordinal}
              id="home_page_suggested_friends"
            >
              <SuggestedFriendHome
                title={topInfos?.friend_suggestion?.title || ""}
                suggestedFriends={suggestedFriends}
              />
            </Box>
          )}
        {isPositiveOrZero(topInfos?.news?.ordinal) && listNews?.length > 0 && (
          <Box order={topInfos?.news?.ordinal} id="home_page_news">
            <NewsComponent title={topInfos?.news?.title || ""} listNews={listNews} />
          </Box>
        )}
        <Box className="sm:w-screen md:w-full order-[99]" id="home_page_feedback">
          <Feedback />
        </Box>
      </div>
      {openModalChooseBook && (
        <CXModal
          title={intl.formatMessage({ id: "title.modal.choose.book" })}
          open={openModalChooseBook}
          handleClose={() => {
            setOpenModalChooseBook(false);
            setListBook([]);
            // dispatch(commonActions.handleHideDialogSurvey(false));
          }}
          minHeight="max-content"
          isShowDivider={false}
          padding={50}
        >
          <div className="text-black pb-4">
            {listBook.map((item, index) => (
              <div key={item?.id} className="hover:bg-greenBland rounded-md ">
                <button
                  type="button"
                  className={`text-left ${
                    index !== listBook.length - 1 ? "border-b border-gray" : "border-none"
                  } w-full py-2 px-2`}
                  onClick={() => {
                    handleDirectToSubjectPage(item);
                    eventTracking("select_book", {
                      book_name: checkDuplicateInArr(listBook)
                        ? `${item?.learning_program?.title} - ${item?.title}`
                        : item?.learning_program?.title,
                      grade_id: userClass,
                      device: navigator?.userAgent
                    });
                  }}
                >
                  {checkDuplicateInArr(listBook) ? (
                    <span>{`${item?.learning_program?.title} - ${item?.title}`}</span>
                  ) : (
                    <span>{item?.learning_program?.title}</span>
                  )}
                </button>
              </div>
            ))}
          </div>
        </CXModal>
      )}
      {/* <DialogAdsInHouse
        open={
          openDialogAdsInHouse &&
          (!dataUserInfos?.member ||
            (!!dataUserInfos?.member &&
              !congratulation.isOpenCongratulation &&
              locationPermission !== LOCATION_PERMISSION_UNDEFINE))
        }
        onClose={() => {
          dispatch(commonActions.handleOpenDialogAdsInHouse(false));
          dispatch(commonActions.handleSaveIsShowDialogAdsInHouseFirstTime(false));
          dispatch(commonActions.handleHideDialogSurvey(false));
        }}
        adsInHouse={adsInHouses?.find((e) => e.position === POSITION_ADS_IN_HOUSE.homeDialog)}
        handleClickAds={handlePostActionClickAdsInHouse}
        isShowAdsDialogFirstTime={isShowAdsDialogFirstTime}
      /> */}
      {openModalConfirmJoinQuiz && (
        <ModalConfirm
          title="common.bottombar.notification"
          content="home.content.modal.confirm.join.quiz"
          onConfirm={() => {
            setOpenModalConfirmJoinQuiz(false);
            router.push(nextUrl);
          }}
          onCancel={() => setOpenModalConfirmJoinQuiz(false)}
          textConfirm="common.ok"
          handleClose={() => setOpenModalConfirmJoinQuiz(false)}
          open={openModalConfirmJoinQuiz}
          styles={undefined}
        />
      )}
      {notifyHome?.type === "HANDLE_TAKE_GIFT_CODE_FAILED" && (
        <AlertDialog
          open={notifyHome?.type === "HANDLE_TAKE_GIFT_CODE_FAILED"}
          setOpen={() => dispatch(homeActions.handleClearNotifyData())}
          content={`${notifyHome.message}(${notifyHome?.code})`}
          title="Thông báo"
          buttonOkKey="common.ok"
          triggerFunc={() => {
            dispatch(homeActions.handleClearNotifyData());
            handleGetGiftCode();
          }}
          isHideCancel
        />
      )}
      {openModalConfirmJoinQuizPremium && (
        <ModalConfirm
          title="common.bottombar.notification"
          content="common.content.modal.confirm.premium"
          onConfirm={() => router.push("/kim-cuong/nang-cap-vip")}
          onCancel={() => setOpenModalConfirmJoinQuizPremium(false)}
          textConfirm="common.ok"
          handleClose={() => setOpenModalConfirmJoinQuizPremium(false)}
          open={openModalConfirmJoinQuizPremium}
          styles={undefined}
        />
      )}
    </>
  );
}

export default Home;
