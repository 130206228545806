/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
/* eslint-disable func-names */
import type { NextPage } from "next";
import React from "react";
import { END } from "redux-saga";
import Home from "../components/features/Homepage/pages/Home";
import Layout from "../components/features/Default/layout/layout";
import { wrapper } from "../app/store";
import { commonActions } from "../components/features/common/redux/actions";
import { getTopInfosSSR, getNewsHomePageSSR, getTopExtraInfosSSR } from "./api/home";
import { BASE_LANGUAGES } from "../components/features/common/utils/constant";

const HomePage: NextPage = (props) => {
  return (
    <Layout pageProps={props}>
      <Home props={props} />
    </Layout>
  );
};

export const getServerSideProps = wrapper.getServerSideProps((store) => async ({ req, res, resolvedUrl }) => {
  const handleGetHomePageData = async () => {
    const grade = store.getState()?.commonNew?.grade;
    const userLanguage = store.getState()?.commonNew?.userLanguage;
    if (grade === store?.getState()?.commonNew?.listTops?.grade) return;
    const paramsGetTop = {
      grade,
      user_language: userLanguage || BASE_LANGUAGES[0]
    };
    await Promise.all([
      getTopInfosSSR(store, paramsGetTop)
        .then((resp: any) => store.dispatch(commonActions.handleGetTopSuccess({ ...resp?.data?.data, grade })))
        .catch((err) => err),
      getNewsHomePageSSR(store)
        .then((resp: any) => store.dispatch(commonActions.handleGetNewsSuccess(resp?.data?.data)))
        .catch((err) => err)
    ]);
  };
  await handleGetHomePageData();

  // end the saga
  store.dispatch(END);
  await store?.sagaTask.toPromise();
  return {
    props: {
      topInfos: store?.getState()?.commonNew?.listTops,
      listNews: store?.getState()?.commonNew?.listNews
    }
  };
});
export default HomePage;
