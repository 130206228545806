import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import Link from "next/link";
import { FormattedMessage } from "react-intl";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import CustomSlider from "../../common/components/CustomSlider";
import RoomCard from "../../common/components/RoomCard";
import { TCommonState } from "../../common/utils/type";
import { eventTracking } from "../../../firebase/firebaseConfig";

function ArenaRoomHot({ roomHot, subjects }) {
  const matches1400 = useMediaQuery("(min-width:1400px)");
  const { grade } = useSelector((state: { commonNew: TCommonState }) => state?.commonNew);
  return (
    <Box className="flex flex-col bg-white py-[24px] px-[16px] lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 md:w-full sm:w-screen">
      <div className="flex mb-4 justify-between items-center">
        <span className="font-semibold text-lg w-[calc(100%-70px)] text-black line-clamp-1" id="hot_room">
          {roomHot?.title || ""}
        </span>
        <Link href={`${grade}/thi-online?tab=phong-thi&category=waiting-room`}>
          <a
            href={`${grade}/thi-online?tab=phong-thi&category=waiting-room`}
            className="text-[13px] text-primary font-[500]"
            target="_blank"
            rel="noreferrer"
            onClick={() => eventTracking("select_room_more", { grade_id: grade })}
          >
            <FormattedMessage id="common.seeMore" />
          </a>
        </Link>
      </div>

      <Box className="w-full">
        {roomHot?.items?.length === 1 ? (
          <Link key={roomHot?.items[0]?.room_hid} href={`${grade}/thi-online/phong-thi/${roomHot?.items[0]?.room_hid}`}>
            <a
              href={`${grade}/thi-online/phong-thi/${roomHot?.items[0]?.room_hid}`}
              rel="noreferrer"
              target="_blank"
              className="w-full"
              onClick={() => eventTracking("select_room_item", { grade_id: grade })}
            >
              <div className="px-2 md:w-[50%] sm:w-full max-w-[550px]">
                <RoomCard
                  room={roomHot?.items[0]}
                  roomsData={{
                    rooms: roomHot?.items,
                    subjects
                  }}
                  handleDirectDetail={() => {}}
                  // index={1}
                  // type="listRoom"
                />
              </div>
            </a>
          </Link>
        ) : (
          <CustomSlider
            isDots={false}
            autoplay={false}
            slidesToScroll={1}
            slidesToShow={matches1400 && roomHot?.items?.length >= 2 ? 2 : 1}
            className="w-full"
            centerMode={matches1400 && roomHot?.items?.length < 2}
          >
            {roomHot?.items?.map((room) => (
              <Link key={room?.room_hid} href={`${grade}/thi-online/phong-thi/${room?.room_hid}`}>
                <a
                  href={`${grade}/thi-online/phong-thi/${room?.room_hid}`}
                  rel="noreferrer"
                  target="_blank"
                  onClick={() => eventTracking("select_room_item", { grade_id: grade })}
                >
                  <div className="px-2 max-w-[550px]">
                    <RoomCard
                      room={room}
                      roomsData={{
                        rooms: roomHot?.items,
                        subjects
                      }}
                      handleDirectDetail={() => {}}
                      // index={index}
                      // type="listRoom"
                    />
                  </div>
                </a>
              </Link>
            ))}
          </CustomSlider>
        )}
      </Box>
    </Box>
  );
}

const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.roomHot, nextProps?.roomHot);
};
export default React.memo(ArenaRoomHot, checkEqualProps);
