import React from "react";
import { FormattedMessage } from "react-intl";
import { TOPIC_TYPE } from "../../common/utils/constant";
import { LIST_EXTRA_INFO } from "../utils/constants";

const SuggestSubjectsCard = ({ item }) => {
  return (
    <div className="flex flex-col">
      <div className="line-clamp-2 text-left h-[50px]">
        <span className="text-primary text-[15px] text-left">{item?.subject_type?.title}: </span>
        <span>{item?.title}</span>
      </div>
      <div className="flex flex-row items-center w-full gap-1 text-gray400 text-[12px] flex-wrap h-[40px]">
        <span className="my-2">
          <FormattedMessage id={LIST_EXTRA_INFO.find((ele) => ele?.type === item?.extra_info?.type)?.id} />
        </span>
        {item?.extra_info?.quiz_time > 0 && (
          <span>
            - <FormattedMessage id="num.minute" values={{ minute: item?.extra_info?.quiz_time }} />
          </span>
        )}
        {item?.extra_info?.num_of_questions && (
          <span>
            {" "}
            - <FormattedMessage id="num.question" values={{ num: item?.extra_info?.num_of_questions }} />{" "}
          </span>
        )}
        {item?.extra_info?.views > 0 && (
          <span>
            -{" "}
            <FormattedMessage
              id={
                item?.extra_info?.type === TOPIC_TYPE.LESSON || item?.extra_info?.type === TOPIC_TYPE.CARD
                  ? "num.who.was.watched"
                  : "num.who.was.worked"
              }
              values={{ num: item?.extra_info?.views }}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default React.memo(SuggestSubjectsCard);
