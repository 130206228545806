/* eslint-disable no-nested-ternary */
import { Box, Button, useMediaQuery } from "@mui/material";
import Link from "next/link";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Image from "next/image";
import { useSelector } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import CustomSlider from "../../common/components/CustomSlider";
import ExchangeGiftItem from "../../common/components/ExchangeGiftItem";
import { TCommonState } from "../../common/utils/type";
import { eventTracking } from "../../../firebase/firebaseConfig";

const GiftItemBackGround = React.memo(() => {
  return (
    <svg width="182" height="112" viewBox="0 0 182 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2929_103922)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 10C6 6.68629 8.68629 4 12 4H170C173.314 4 176 6.68629 176 10V61C172.134 61 169 64.134 169 68C169 71.866 172.134 75 176 75V98C176 101.314 173.314 104 170 104H12C8.68629 104 6 101.314 6 98V75C9.86599 75 13 71.866 13 68C13 64.134 9.86599 61 6 61V10Z"
          fill="#FFEBEF"
        />
        <line x1="14" y1="67.85" x2="168" y2="67.85" stroke="#9E9E9E" strokeWidth="0.3" strokeDasharray="2 4" />
      </g>
      <defs>
        <filter
          id="filter0_d_2929_103922"
          x="0"
          y="0"
          width="182"
          height="112"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2929_103922" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2929_103922" result="shape" />
        </filter>
      </defs>
    </svg>
  );
});

const GiftItemComponent = ({ gift, handleExchangeGiftItem }) => {
  const { grade } = useSelector((state: { commonNew: TCommonState }) => state?.commonNew);
  const isShowButtonExchange =
    !gift?.fee ||
    ((gift?.fee?.diamond?.available === true || gift?.fee?.diamond?.available === undefined) &&
      (gift.fee?.star?.available === true || gift.fee?.star?.available === undefined));
  return (
    <div className="relative">
      <Link className="w-[180px]" href={`qua-tang/${gift?.gift_item_id}`}>
        <a
          href={`qua-tang/${gift?.gift_item_id}`}
          rel="noreferrer"
          target="_blank"
          onClick={() => eventTracking("select_giftcode_item", { grade_id: grade })}
        >
          <div className="relative border]">
            <div className="relative">
              <GiftItemBackGround />
            </div>
            <div className="absolute top-3 left-[15px]">
              <Image
                src={gift?.image_url || "/assets/images/giftItemLarge.png"}
                alt="redeem gift"
                width={44}
                height={44}
                className="object-scale-down"
              />
            </div>
            <span className="absolute top-[15px] left-[65px] w-[100px] text-primary font-[500] text-[16px] leading-[19.36px] line-clamp-2">
              {gift.title}
            </span>
          </div>
        </a>
      </Link>
      {isShowButtonExchange ? (
        <Button
          className="absolute w-[100px] h-[24px] rounded-[30px] bg-primary left-[40px] bottom-[13px]"
          onClick={() => handleExchangeGiftItem(gift)}
        >
          <span className="text-white text-[12px] font-[400] normal-case">
            <FormattedMessage id="gift.button.title.submit" />
          </span>
        </Button>
      ) : (
        <Link className="w-[180px" href={`qua-tang/${gift?.gift_item_id}`}>
          <a href={`qua-tang/${gift?.gift_item_id}`} rel="noreferrer" target="_blank">
            <Button className="absolute w-[100px] h-[24px] rounded-[30px] bg-primary left-[40px] bottom-[13px]">
              <span className="text-white text-[12px] font-[400] normal-case">
                <FormattedMessage id="IDS_Detail" />
              </span>
            </Button>
          </a>
        </Link>
      )}
    </div>
  );
};

function RedeemGift({ redeemGifts, handleGetTopExtra }) {
  const [giftInfo, setGiftInfo] = useState<any>();
  const { grade } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const matches1620 = useMediaQuery("(min-width:1620px)");
  const matches1480 = useMediaQuery("(min-width:1480px)");
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const matches768 = useMediaQuery("(min-width:768px)");
  const matches520 = useMediaQuery("(min-width:520px)");
  const lengthShowGiftCode = matches1620
    ? 4
    : matches1480
    ? 3
    : matches1100
    ? 2
    : matches768
    ? 1.5
    : matches520
    ? 2
    : 1;

  const handleExchangeGiftItem = (info) => {
    setGiftInfo(info);
  };

  return (
    <>
      <Box className="flex flex-col bg-white py-[24px] px-[16px] lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 md:w-full sm:w-screen">
        <div className="flex mb-4 justify-between items-center">
          <span className="font-semibold text-lg text-black w-[calc(100%-70px)] line-clamp-1" id="hot_room">
            {redeemGifts?.title || ""}
          </span>
          <Link href="/qua-tang">
            <a
              href="/qua-tang"
              className="text-[13px] text-primary font-[500]"
              target="_blank"
              onClick={() => eventTracking("select_giftcode_more", { grade_id: grade })}
            >
              <FormattedMessage id="common.seeMore" />
            </a>
          </Link>
        </div>

        <Box className="">
          {redeemGifts?.items?.length <= lengthShowGiftCode ? (
            <Box className="flex gap-x-5">
              {redeemGifts?.items?.map((gift) => (
                <GiftItemComponent
                  key={gift?.gift_item_id}
                  gift={gift}
                  handleExchangeGiftItem={handleExchangeGiftItem}
                />
              ))}
            </Box>
          ) : (
            <CustomSlider
              isDots={false}
              autoplay={false}
              slidesToScroll={1}
              slidesToShow={lengthShowGiftCode}
              centerMode
              // centerMode={matches1400 && redeemGifts?.items?.length < 2}
            >
              {redeemGifts?.items?.map((gift) => (
                <GiftItemComponent
                  key={gift?.gift_item_id}
                  gift={gift}
                  handleExchangeGiftItem={handleExchangeGiftItem}
                />
              ))}
            </CustomSlider>
          )}
        </Box>
      </Box>
      {!isEmpty(giftInfo) && (
        <ExchangeGiftItem
          giftInfo={giftInfo}
          handleClose={() => {
            setGiftInfo({});
          }}
          handleSuccess={() => {
            handleGetTopExtra();
          }}
        />
      )}
    </>
  );
}
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.redeemGifts, nextProps?.redeemGifts);
};
export default React.memo(RedeemGift, checkEqualProps);
