/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-filename-extension */
import { Typography, useTheme } from "@mui/material";
import Rating from "@mui/material/Rating";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import React from "react";
import Image from "next/image";
import { FormattedMessage } from "react-intl";
import styles from "../style.module.scss";
import imageFeedback1 from "../../../../public/assets/images/feedback1.png";
import imageFeedback2 from "../../../../public/assets/images/feedback2.png";
import imageFeedback3 from "../../../../public/assets/images/feedback3.png";
import imageFeedback4 from "../../../../public/assets/images/feedback4.png";
import imageFeedback5 from "../../../../public/assets/images/feedback5.png";
import imageFeedback6 from "../../../../public/assets/images/feedback6.png";
import imageFeedback7 from "../../../../public/assets/images/feedback7.png";
import imageFeedback8 from "../../../../public/assets/images/feedback8.png";
import IconStar from "../../../../public/assets/icons/IconStar";
import { TTheme } from "../../../utils/configs/setupTheme";
import CustomSlider from "../../common/components/CustomSlider";

function Feedback() {
  const matches1300 = useMediaQuery("(min-width:1300px)");
  const isMobile = useMediaQuery("(max-width:520px)");
  const isTabletPro = useMediaQuery("(max-width:1024px)");
  const theme: TTheme = useTheme();
  return (
    <Box className="flex flex-col box-cover text-black" style={{ backgroundColor: "white" }}>
      <span
        className="text-black font-semibold text-lg mb-2"
        style={{ fontWeight: "bold", display: "flex", width: "100%" }}
      >
        <FormattedMessage id="feedback" />
      </span>
      <div>
        <CustomSlider
          isDots={false}
          autoplay
          slidesToShow={isMobile ? 2 : isTabletPro ? 3 : 4}
          slidesToScroll={1}
          centerMode={false}
        >
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:ml-2 md:my-1 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:ml-[4px]`}
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image src={imageFeedback1} alt="" className={`${styles["images-feedback"]} rounded-[50%]`} width={40} height={40} />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
                Chu Linh
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="great" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:my-1 sm:mx-1`}
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <div>
              <Image src={imageFeedback2} alt="" className={`${styles["images-feedback"]} rounded-[50%]`} width={40} height={40} />
              </div>
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
                Ngân Thanh
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="very.good" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image className={`${styles["images-feedback"]} rounded-[50%]`} width={40} height={40} src={imageFeedback3}/>
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
                Vân anh Vu
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="feedback.3" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
          >
            <Box display="flex" alignItems="center" mb={3 / 2} className="gap-2 h-[56px] mb-2">
              <Image className={`${styles["images-feedback"]} rounded-[50%]`} width={40} height={40} src={imageFeedback4}/>
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
                Myo
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                {/* Xịn lắm mn ạ 💙 */}
                <FormattedMessage id="feedback.4" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image src={imageFeedback5} alt="" className={`${styles["images-feedback"]} rounded-[50%]`} width={40} height={40} />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
                Hiển Dương
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="feedback.5" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:my-1 sm:mx-1`}
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <div>
                <Image src={imageFeedback6} className={`${styles["images-feedback"]} rounded-[50%]`} width={40} height={40} />
              </div>
              
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
                ti minh
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="feedback.6" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image className={`${styles["images-feedback"]} rounded-[50%]`} width={40} height={40} src={imageFeedback7} alt="" />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
                Name No
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="feedback.7" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
          >
            <Box display="flex" alignItems="center" mb={3 / 2} className="gap-2 h-[56px] mb-2">
              <Image className={`${styles["images-feedback"]} rounded-[50%]`} width={40} height={40} src={imageFeedback8} alt="" />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
                Bình Lương
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                {/* Xịn lắm mn ạ 💙 */}
                <FormattedMessage id="feedback.8" />
              </Typography>
            </div>
          </Box>
        </CustomSlider>
      </div>
    </Box>
  );
}

export default Feedback;
