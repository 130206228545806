/* eslint-disable no-nested-ternary */
import React from "react";

import { useMediaQuery } from "@mui/material";
import SuggestSubjectsCard from "./SuggestSubjectsCard";
import CustomSlider from "../../common/components/CustomSlider";

const SuggestSubjectComponent = (props) => {
  const { title, suggestSubjects, handleDirectPage } = props;
  const desktop = useMediaQuery("(min-width:1440px)");
  return (
    <div className="box-cover px-0">
      <span className="text-black font-semibold text-lg line-clamp-2 px-4">
        {title}
      </span>
      <div className="w-full px-4">
        {suggestSubjects?.items?.length >= 2 ? (
          <CustomSlider
            isDots={false}
            autoplay
            centerMode={false}
            slidesToShow={!desktop ? 1 : 2}
            className="px-2 gap-x-4 pb-3 pt-2"
          >
            {suggestSubjects?.items?.map((item) => (
              <button
                key={item?.id}
                type="button"
                className="sm:w-[93%] min-h-[107px] max-h-max rounded-[6px] shadow-md px-4 py-4 mr-4 my-2 cursor-pointer"
                onClick={() =>
                  handleDirectPage(item?.extra_info, item?.direct_link)
                }
              >
                <SuggestSubjectsCard item={item} />
              </button>
            ))}
          </CustomSlider>
        ) : (
          suggestSubjects?.items?.map((item) => (
            <button
              key={item?.id}
              type="button"
              className="sm2:w-[322px] sm:w-[93%] min-h-[107px] max-h-max rounded-[6px] px-4 py-4 mr-4 my-2 cursor-pointer"
              onClick={() =>
                handleDirectPage(item?.extra_info, item?.direct_link)
              }
              style={{
                boxShadow: "0px 2px 6px rgb(0 0 0 / 15%)"
              }}
            >
              <SuggestSubjectsCard item={item} />
            </button>
          ))
        )}
      </div>
    </div>
  );
};

export default React.memo(SuggestSubjectComponent);
