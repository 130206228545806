import { Box, Divider } from "@mui/material";
import { isEqual } from "lodash";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";

function LearningStatistic({ learningStatistic }) {
  return (
    <Box className="flex flex-col bg-white py-[24px] px-[16px] lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 md:w-full sm:w-screen">
      <div className="flex mb-4 justify-between items-center">
        <span
          className="font-semibold text-lg text-black w-[calc(100%-70px)] line-clamp-1"
          id="learning_statistic_title"
        >
          {learningStatistic?.title || ""}
        </span>
        <Link href="/thong-ke" className="border">
          <a href="/thong-ke" className="text-[13px] text-primary font-[500]" target="_blank">
            <FormattedMessage id="common.seeMore" />
          </a>
        </Link>
      </div>

      <Box className="flex border-2 border-gray flex-wrap rounded-[16px] py-2 w-full text-black1 text-[15px]">
        <Box className="w-[33%] flex justify-center flex-col h-[68px] md:pt-3 sm:pt-1 items-center">
          <span className="text-center">
            <FormattedMessage id="home.learningStatistic.timeLearning" />
          </span>
          <div>
            <span className="sm:text-[20px] md:text-[33px] font-[700] text-primary-gradient">
              {Number((learningStatistic?.learning_per_week || 0) / 3600).toFixed(2)}
            </span>{" "}
            <span className="lowercase text-gray400 text-[12px]">
              <FormattedMessage id="common.hour" />
            </span>
          </div>
        </Box>
        <Divider orientation="vertical" sx={{ borderWidth: 1 }} />
        <Box className="w-[33%] flex justify-center flex-col h-[68px] md:pt-3 sm:pt-1 items-center">
          <span className="text-center">
            <FormattedMessage id="home.learningStatistic.correctRate" />
          </span>
          <div>
            <span className="sm:text-[20px] md:text-[33px] font-[700] text-primary-gradient">
              {(Number(learningStatistic?.correct_quiz_rate) * 100).toFixed(2)}
            </span>{" "}
            <span className="text-gray400 text-[12px]">%</span>
          </div>
        </Box>
        <Divider orientation="vertical" sx={{ borderWidth: 1 }} />
        <Box className="md:w-[33%] sm:w-[32%] flex justify-center flex-col h-[68px] md:pt-3 sm:pt-1 items-center">
          <span className="text-center">
            <FormattedMessage id="home.learningStatistic.numOfQuestions" />
          </span>
          <div>
            <span className="sm:text-[20px] md:text-[33px] font-[700] text-primary-gradient">
              {learningStatistic?.num_of_question_done}
            </span>{" "}
            <span className="text-gray400 text-[12px]">
              <FormattedMessage id="topicAnalysis.unit.title" />
            </span>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.learningStatistic, nextProps?.learningStatistic);
};
export default React.memo(LearningStatistic, checkEqualProps);
