import { Box, useMediaQuery } from "@mui/material";
import { isEqual } from "lodash";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { eventTracking } from "../../../firebase/firebaseConfig";
import CustomSlider from "../../common/components/CustomSlider";
import { TCommonState } from "../../common/utils/type";
import PostItem from "../../questionAndAnswer/components/PostItem";

function NewQuestionPost({ newestPosts, subjects }) {
  const matches1400 = useMediaQuery("(min-width:1399px)");
  const slidesToShow = matches1400 && newestPosts?.items?.length >= 2 ? 2 : 1;
  const { grade } = useSelector((state: { commonNew: TCommonState }) => state?.commonNew);
  return (
    <Box className="flex flex-col bg-white py-[24px] px-[16px] lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 md:w-full sm:w-screen">
      <div className="flex mb-4 justify-between items-center">
        <span className="font-semibold text-lg text-black w-[calc(100%-70px)] line-clamp-1" id="hot_room">
          {newestPosts?.title || ""}
        </span>
        <Link href="hoi-dap?tab=thao-luan">
          <a
            href="hoi-dap?tab=thao-luan"
            className="text-[13px] text-primary font-[500]"
            target="_blank"
            onClick={() => eventTracking("select_post_more", { grade_id: grade })}
          >
            <FormattedMessage id="common.seeMore" />
          </a>
        </Link>
      </div>

      <Box className="w-full">
        {newestPosts?.items?.length > 1 ? (
          <CustomSlider
            isDots={false}
            autoplay={false}
            slidesToScroll={1}
            slidesToShow={slidesToShow}
            className="flex"
            centerMode={false}
          >
            {newestPosts?.items?.map((postItem) => (
              <Link key={postItem?.post_id} href={`hoi-dap/${postItem?.post_id}`}>
                <a
                  href={`hoi-dap/${postItem?.post_id}`}
                  rel="noreferrer"
                  target="_blank"
                  onClick={() => eventTracking("select_post_item", { grade_id: grade })}
                >
                  <div className="py-2 mx-2 h-[200px] max-w-[550px] border-gray border rounded-[16px]">
                    <PostItem
                      postItem={postItem}
                      packages={subjects}
                      handleVote={() => {}}
                      handleSavePost={() => {}}
                      page="homepage"
                    />
                  </div>
                </a>
              </Link>
            ))}
          </CustomSlider>
        ) : (
          <div className="flex overflow-x-auto border border-gray py-2 mx-2 h-[200px] w-[50%] max-w-[550px] rounded-[16px]">
            <Link key={newestPosts?.items[0]?.post_id} href={`hoi-dap/${newestPosts?.items[0]?.post_id}`}>
              <a
                href={`hoi-dap/${newestPosts?.items[0]?.post_id}`}
                rel="noreferrer"
                target="_blank"
                className="w-full"
                onClick={() => eventTracking("select_post_item", { grade_id: grade })}
              >
                <PostItem
                  postItem={newestPosts?.items[0]}
                  packages={subjects}
                  handleVote={() => {}}
                  handleSavePost={() => {}}
                  page="homepage"
                />
              </a>
            </Link>
          </div>
        )}
      </Box>
    </Box>
  );
}
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.newestPosts, nextProps?.newestPosts);
};
export default React.memo(NewQuestionPost, checkEqualProps);
