/* eslint-disable no-unused-vars */
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Box, Button, TextField, useTheme } from "@mui/material";
import React from "react";
import CXModal from "../../common/components/CXModal";
import { TTheme } from "../../../utils/configs/setupTheme";

const useStyles = makeStyles((theme: TTheme) => ({
  cssLabel: {
    color: "#666666 !important"
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.gray} !important`,
      border: "1px solid !important",
      borderRadius: "16px"
    }
  },

  cssFocused: {},
  notchedOutline: {
    borderWidth: "1px",
    borderColor: `${theme.palette.primary.gray} !important`,
    borderRadius: "16px",
    width: "100%"
  }
}));

function ModalFillInfo({
  openModalFillInfo,
  closeModal,
  valuePhone,
  changePhone,
  valueNote,
  changeNote,
  submit,
  errorPhone,
  notify
}: any) {
  const intl = useIntl();
  const theme: TTheme = useTheme();
  const classes = useStyles(theme);

  return (
    <CXModal
      title={intl.formatMessage({
        id: "gift.modal.info.title"
      })}
      open={openModalFillInfo}
      handleClose={closeModal}
      minHeight="100"
      isShowDivider={false}
      className="rounded-[16px]"
    >
      <div className="py-0">
        <div className="md:center-col sm:w-[300px] overflow-hidden md:w-full px-4">
          <Box className="sm:py-0 md:center-row" height={80}>
            <span className="text-[15px]">
              <FormattedMessage id="gift.modal.info.label.phone.title" />
            </span>
            <TextField
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline
                },
                inputMode: "numeric"
              }}
              placeholder={intl.formatMessage({
                id: "gift.modal.info.label.phone"
              })}
              size="small"
              value={valuePhone}
              onChange={(e) => changePhone(e)}
              className="w-full md:w-full outline-none text-[13px] mt-[10px]"
              error={errorPhone && errorPhone.length > 0}
              helperText={errorPhone.length === 0 ? undefined : errorPhone}
            />
          </Box>
          <Box className="md:center-row mt-[10px]" height={80}>
            <span className="text-[15px]">
              <FormattedMessage id="gift.modal.info.label.note" />
            </span>
            <input
              placeholder={intl.formatMessage({
                id: "gift.modal.info.placeholder.note"
              })}
              className="w-full md:w-full py-[7px] px-[10px] outline-none text-[13px] mt-[10px]"
              style={{
                border: `1px solid ${theme.palette.primary.gray}`,
                borderRadius: "16px"
              }}
              value={valueNote}
              onChange={(e) => changeNote(e)}
            />
          </Box>
        </div>
      </div>
      <div className="flex justify-center my-[5px] mt-[10px] mb-[12px]">
        <Button
          onClick={closeModal}
          className="flex justify-center items-center w-[149px] h-[44px] py-[14px] px-[34px] text-primary text-[13px] font-[500] rounded-[8px] normal-case"
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          className={`flex justify-center items-center w-[149px] h-[44px] py-[14px] px-[34px] text-white text-[13px] font-[500] rounded-[8px] normal-case ${
            valuePhone.length === 0 || notify?.type === "GET_GIFT_ITEM_EXCHANGE_FAILED" ? "bg-gray" : "bg-primary"
          }`}
          disabled={valuePhone.length === 0 || notify?.type === "GET_GIFT_ITEM_EXCHANGE_FAILED"}
          onClick={submit}
        >
          <FormattedMessage id="common.send" />
        </Button>
      </div>
    </CXModal>
  );
}

export default ModalFillInfo;
