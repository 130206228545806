/* eslint-disable react/no-danger */
import React from "react";
import { TNews } from "../../common/utils/type";
import NextImage from "../../common/Image/NextImage";

type TProps = {
  newItem: TNews;
  listNews: TNews[];
};
const NewsCard: React.FC<TProps> = ({ newItem, listNews }) => {
  return (
    <div className="flex flex-col rounded-md">
      <div className="relative">
        <NextImage
          src={newItem?.image_url || "/assets/images/defaultImageNews.png"}
          className={` w-full ${
            listNews?.length > 2 ? "aspect-[3/2]" : "md:aspect-[3/1] sm:aspect-[3/2]"
          } rounded-t-md border-b-none`}
          classImage="rounded-t-md"
        />
        <span className="bg-[#ECFDF3] text-[#027A48]  absolute text-[10px] px-2 py-1 rounded-[15px] right-2 bottom-1.5 font-semibold">
          {newItem?.category}
        </span>
      </div>
      <div className="text-start px-2 h-[128px] flex-col gap-y-2 flex mt-2">
        <span className="text-[15px] text-blackQuiz line-clamp-2 text-left">{newItem?.title}</span>
        <span
          className="line-clamp-4 text-gray400 text-[12px] text-left"
          dangerouslySetInnerHTML={{ __html: newItem?.description }}
        />
      </div>
    </div>
  );
};
export default NewsCard;
