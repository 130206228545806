/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useTheme } from "@mui/material";
import React from "react";
import { TTheme } from "../../../utils/configs/setupTheme";
import { isUrlValid } from "../utils/constants";

type TProps = {
  recommendItem: any;
};

const RecommendCard: React.FC<TProps> = ({ recommendItem }) => {
  const theme: TTheme = useTheme();
  return (
    <div className={`rounded-md w-full h-full ${recommendItem?.isAds ? "inline" : "flex flex-col"}`}>
      <img
        className={`w-full ${recommendItem?.isAds ? "rounded-md" : "rounded-t-md"} `}
        style={{
          objectFit: recommendItem?.isAds ? "fill" : "contain",
          aspectRatio: recommendItem?.isAds ? "3/1.45" : "3/1"
        }}
        src={recommendItem.photo_url}
        alt={recommendItem?.title}
        onClick={() => {
          if (recommendItem?.isAds) {
            window.open(recommendItem?.web_link, "_blank");
          }
        }}
      />
      {!recommendItem?.isAds && (
        <div
          className="w-full flex-col px-2 py-1 rounded-b-md text-start"
          style={{
            backgroundColor: recommendItem.background_color || "#ffffff"
          }}
        >
          <div
            className="text-[15px] h-[24px]"
            style={{
              color: recommendItem?.title_color
            }}
          >
            <button
              type="button"
              onClick={() =>
                isUrlValid(recommendItem?.web_link as string) && window.open(recommendItem?.web_link, "_blank")
              }
              className=" inline h-full text-left"
            >
              <span className="line-clamp-1"> {recommendItem?.title}</span>
            </button>
          </div>
          <div
            className={`w-full flex flex-row items-center h-[34px] ${
              recommendItem?.short_description ? "justify-between" : "justify-end"
            } `}
          >
            <button
              type="button"
              className={`text-[12px] w-full flex text-left ${recommendItem?.button_config ? "w-3/4" : "w-full"}`}
              style={{
                color: recommendItem?.short_description_color || theme.palette.primary.gray400
              }}
              onClick={() =>
                isUrlValid(recommendItem?.web_link as string) ? window.open(recommendItem?.web_link, "_blank") : null
              }
            >
              <span className="text-start line-clamp-2 ">{recommendItem?.short_description}</span>
            </button>
            {recommendItem?.button_config && (
              <button
                type="button"
                style={{
                  color: recommendItem?.button_config?.text_color || "black",
                  backgroundColor: recommendItem?.button_config?.background_color || "white",
                  borderColor: recommendItem?.button_config?.border_color || "white"
                }}
                className="px-4 py-1.5 rounded-[30px] text-xs"
                onClick={() =>
                  isUrlValid(recommendItem?.web_link as string) ? window.open(recommendItem?.web_link, "_blank") : null
                }
              >
                <span className="w-full line-clamp-1"> {recommendItem?.button_config?.text_content}</span>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(RecommendCard);
