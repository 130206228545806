import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import Link from "next/link";
import { isEqual } from "lodash";
import { eventTracking } from "../../../firebase/firebaseConfig";
import CustomSlider from "../../common/components/CustomSlider";
import { TNews } from "../../common/utils/type";
import NewsCard from "./NewsCard";

type TProps = {
  title: string;
  listNews: TNews[];
};
const NewsComponent: React.FC<TProps> = ({ title, listNews }) => {
  const mobile = useMediaQuery("(min-width:520px)");
  const router = useRouter();
  return (
    <div className="p-4 bg-white lg:rounded-[6px] md:rounded-[6px] sm:rounded-0">
      <div className="flex justify-between items-center">
        <span className="text-black font-semibold text-lg" id="home_page_new_title">
          {title}
        </span>
        <Link href="/tin-tuc" id="home_page_new_btn">
          <a
            href="/tin-tuc"
            className="text-[13px] font-semibold hover:underline text-primary text-end"
            onClick={() => eventTracking("select_news_more")}
          >
            <FormattedMessage id="friend.add" />
          </a>
        </Link>
      </div>
      <div className="px-4 sm:px-0">
        {listNews?.length > 2 ? (
          <CustomSlider
            isDots={false}
            autoplay
            centerMode={false}
            slidesToShow={!mobile ? 2 : 3}
            className="lg:px-4 sm:px-0 gap-x-4 py-4"
          >
            {listNews?.map((item, index) => (
              <button
                type="button"
                className="w-[93%] rounded-[6px] shadow-md my-2 cursor-pointer"
                key={index}
                onClick={() => eventTracking("select_news_item", {}, () => router.push(`/tin-tuc/${item?.slug}`))}
                id="home_page_new_item"
              >
                <NewsCard newItem={item} listNews={listNews} />
              </button>
            ))}
          </CustomSlider>
        ) : (
          <div className="flex items-center flex-row gap-2">
            {listNews?.map((item, index) => (
              <button
                type="button"
                className="rounded-[6px] shadow-md mr-4 my-2 cursor-pointer w-[45%]"
                key={index}
                onClick={() => eventTracking("select_news_item", {}, () => router.push(`/tin-tuc/${item?.slug}`))}
                id="home_page_new_item"
              >
                <NewsCard newItem={item} listNews={listNews} />
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.listNews, nextProps?.listNews);
};
export default React.memo(NewsComponent, checkEqualProps);
